import './AboutSVG.scss';
function AboutSVG() {
	return ( 
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 223.2 212.59" id="about-page-logo">
		 {/* <g id="whitebackground">
    <rect class="cls-5" x=".25" y=".25" width="222.7" height="212.09"/>
  </g> */}
  <g id="logo">
    <g>
      <path class="cls-4" d="m106.82,147.05s-.13,0-.14,0c-1.79-.06-2.84-.83-3.4-1.47-.6-.68-.98-1.54-1.14-2.62h-5.26c-12.98,0-23.54-10.56-23.54-23.54v-8.79c-4.83,6.1-10.56,10.47-14.33,8.29-1.63-.94-3.32-3.37-2.1-9.62.85-4.36,2.93-9.56,5.88-14.66,5.69-9.86,14.1-18.32,19.14-15.41,1.32.76,2.67,2.5,2.49,6.42,3.72-2.32,8.03-3.57,12.45-3.57h10.02v-15.04c0-.54.29-1.04.76-1.31.47-.27,1.05-.26,1.51.02l6.77,4.06,6.01-3.13c.55-.28,1.21-.21,1.68.2l4.77,4.14,9.04-2.33c.52-.13,1.07.02,1.45.41.38.39.52.94.37,1.46l-3.62,12.59c.66.21,1.31.44,1.94.7.04-3.01,1.18-4.44,2.26-5.13,1.59-1.01,4.52-1.38,9.51,2.59,3.47,2.77,7.12,7.02,10.28,11.99,3.16,4.96,5.47,10.08,6.5,14.39,1.48,6.19-.1,8.7-1.68,9.71-2.84,1.81-7.32-.1-12.32-5.12v7.13c0,12.98-10.56,23.54-23.54,23.54h-17.42c-.25,1.18-.7,2.12-1.35,2.82-.78.83-1.81,1.27-2.98,1.27Zm-.5-4.09c.08.04.23.08.48.08,0,0,.09,0,.09,0,.02-.02.04-.04.06-.08h-.62Zm-1.79-3h4.3c.17,0,.34.03.5.09.16-.06.33-.09.5-.09h18.73c11.33,0,20.54-9.21,20.54-20.54v-10.98c0-.63.39-1.19.98-1.41.59-.22,1.25-.04,1.66.44,5.49,6.48,9.78,8.24,11.07,7.42.77-.49,1.33-2.48.38-6.48-.96-4-3.13-8.79-6.11-13.48s-6.4-8.69-9.62-11.25c-3.21-2.56-5.25-2.89-6.03-2.4-.82.52-1.08,2.38-.7,4.85.09.57-.15,1.13-.62,1.46s-1.09.36-1.59.08c-1.61-.89-3.33-1.57-5.12-2-.16-.04-.3-.1-.44-.18-.15.02-.31.02-.47-.02-1.3-.25-2.62-.38-3.93-.38h-18.72c-.19,0-.38-.04-.56-.11l-.18-.07c-.21.12-.46.18-.72.18h-11.52c-4.93,0-9.7,1.78-13.43,5-.49.42-1.19.49-1.74.16-.55-.33-.84-.97-.71-1.6.89-4.27.25-6.34-.57-6.82-2.07-1.2-8.97,3.79-15.05,14.31-2.78,4.81-4.74,9.69-5.53,13.73-.78,4.03-.14,6,.66,6.45,1.73,1,7.23-2.33,13.08-11.15.37-.55,1.05-.8,1.68-.61.63.19,1.07.77,1.07,1.44v13.41c0,11.33,9.21,20.54,20.54,20.54h6.66c.17,0,.34.03.5.09.16-.06.32-.09.5-.09Zm6.36-57.88h17.68c1.04,0,2.1.07,3.14.21l2.48-8.63-6.08,1.57c-.48.12-.98,0-1.36-.32l-4.47-3.88-5.74,2.99c-.46.24-1.02.22-1.46-.04l-4.19-2.51v10.62Z"/>
      <rect x="74.83" y="83.58" width="75.78" height="57.88" rx="22.04" ry="22.04"/>
      <path class="cls-3" d="m104.13,139.84l5.21.18s.47,5.12-2.6,5.03-2.79-3.16-2.6-5.21Z"/>
      <line class="cls-1" x1="106.7" y1="142.44" x2="106.7" y2="139"/>
      <rect class="cls-4" x="81.66" y="91.21" width="61.4" height="49.25" rx="24.54" ry="24.54"/>
      <g>
        <ellipse class="cls-4" cx="124.62" cy="130.68" rx="17.68" ry="9.78"/>
        <ellipse class="cls-4" cx="100.1" cy="130.68" rx="17.68" ry="9.78"/>
      </g>
      <ellipse cx="70.46" cy="99.08" rx="21.42" ry="7.37" transform="translate(-50.57 110.56) rotate(-60)"/>
      <ellipse cx="152.13" cy="98.08" rx="7.37" ry="21.42" transform="translate(-28.87 96.99) rotate(-32.46)"/>
      <path class="cls-3" d="m137.08,70.86l-9.21,2.37-5.3-4.6-6.7,3.49-6.98-4.19v15.79s13.64,6.3,24.14,1.23l4.05-14.09Z"/>
      <ellipse cx="124.62" cy="121.14" rx="8.38" ry="14.05"/>
      <ellipse cx="99.39" cy="121.14" rx="8.38" ry="14.05"/>
      <ellipse class="cls-4" cx="94.18" cy="118.66" rx="3.93" ry="6.6"/>
      <ellipse class="cls-4" cx="118.58" cy="118.66" rx="3.93" ry="6.6"/>
      <path class="cls-2" d="m111.24,138.07l-4.54-5.58s3.85-4.19,8.78,0"/>
    </g>
  </g>
  <g id="words">
    <g>
      <g>
        <text class="cls-7" transform="translate(167.94 158.11) rotate(139.21)"><tspan x="0" y="0">W</tspan></text>
        <text class="cls-7" transform="translate(155.55 168.55) rotate(148.52)"><tspan x="0" y="0">a</tspan></text>
        <text class="cls-7" transform="translate(147.28 173.59) rotate(155.31)"><tspan x="0" y="0">r</tspan></text>
        <text class="cls-7" transform="translate(139.63 177.18) rotate(162.26)"><tspan x="0" y="0">p</tspan></text>
        <text class="cls-7" transform="translate(130.13 180.21) rotate(169.45)"><tspan x="0" y="0">e</tspan></text>
        <text class="cls-7" transform="translate(121.27 181.94) rotate(176.88)"><tspan x="0" y="0">d</tspan></text>
        <text class="cls-7" transform="translate(110.6 182.37) rotate(-177.56)"><tspan x="0" y="0"> </tspan></text>
        <text class="cls-7" transform="translate(106.55 182.38) rotate(-171.9)"><tspan x="0" y="0">P</tspan></text>
        <text class="cls-7" transform="translate(95.18 180.74) rotate(-163.52)"><tspan x="0" y="0">u</tspan></text>
        <text class="cls-7" transform="translate(85.01 177.74) rotate(-155.74)"><tspan x="0" y="0">p</tspan></text>
        <text class="cls-7" transform="translate(75.93 173.67) rotate(-148.19)"><tspan x="0" y="0">p</tspan></text>
        <text class="cls-7" transform="translate(67.44 168.44) rotate(-140.55)"><tspan x="0" y="0">y</tspan></text>
        <text class="cls-7" transform="translate(59.51 161.83) rotate(-133.96)"><tspan x="0" y="0">!</tspan></text>
      </g>
      <text class="cls-6" transform="translate(59.83 49.91) rotate(-35.95)"><tspan x="0" y="0">W</tspan></text>
      <text class="cls-6" transform="translate(73.06 40.56) rotate(-26.64)"><tspan x="0" y="0">a</tspan></text>
      <text class="cls-6" transform="translate(81.73 36.23) rotate(-19.85)"><tspan x="0" y="0">r</tspan></text>
      <text class="cls-6" transform="translate(89.65 33.3) rotate(-12.9)"><tspan x="0" y="0">p</tspan></text>
      <text class="cls-6" transform="translate(99.37 31.08) rotate(-5.71)"><tspan x="0" y="0">e</tspan></text>
      <text class="cls-6" transform="translate(108.35 30.11) rotate(1.73)"><tspan x="0" y="0">d</tspan></text>
      <text class="cls-6" transform="translate(119.02 30.58) rotate(7.28)"><tspan x="0" y="0"> </tspan></text>
      <text class="cls-6" transform="translate(123.05 30.91) rotate(12.94)"><tspan x="0" y="0">P</tspan></text>
      <text class="cls-6" transform="translate(134.24 33.5) rotate(21.32)"><tspan x="0" y="0">u</tspan></text>
      <text class="cls-6" transform="translate(144.12 37.35) rotate(29.11)"><tspan x="0" y="0">p</tspan></text>
      <text class="cls-6" transform="translate(152.83 42.17) rotate(36.65)"><tspan x="0" y="0">p</tspan></text>
      <text class="cls-6" transform="translate(160.85 48.1) rotate(44.29)"><tspan x="0" y="0">y</tspan></text>
      <text class="cls-6" transform="translate(168.19 55.36) rotate(50.88)"><tspan x="0" y="0">!</tspan></text>
    </g>
  </g>
</svg>);
}

export default AboutSVG;