import './Contact.scss';
function Contact() {
	return ( 
		<section id="contact">
			<p>
				oh dear mother of god why would you want to contact me.
			</p>
			<p>
				I mean, really?
			</p>
			<p>
				If you must, follow the trail from the about page.
			</p>
		</section>
	 );
}

export default Contact;