import "./Pagination.scss";
import { Link, useNavigate } from "react-router-dom";
import ComicsJSON from "../json/comics.json";

function Pagination({ active, randoms, addToRandoms, clearRandoms }) {
  const navigate = useNavigate();
  async function goToRandom() {
    if (ComicsJSON.comics.length === randoms.length) {
      clearRandoms();
    }
    let index = Math.ceil(Math.random() * ComicsJSON.comics.length);
    while (randoms.includes(index)) {
      index = Math.ceil(Math.random() * ComicsJSON.comics.length);
    }
    await addToRandoms(index);
    navigate(`/comics/${ComicsJSON.comics[index - 1]}`);
  }

  function calculateNext(q){
	return ComicsJSON.comics[ComicsJSON.comics.indexOf(active) + q];
  }

  function disable(q, gt) {
	let len = ComicsJSON.comics.length;
	let index = ComicsJSON.comics.indexOf(active)
	return gt ? !!(index > len + q) : !!(index < q);
  }

  return (
    <section id="pagination">
      <div>
        <button onClick={() => navigate(`/comics/${calculateNext(-5)}`)} disabled={disable(5)}>-5</button>
        <button onClick={() => navigate(`/comics/${calculateNext(-1)}`)} disabled={disable(1)}>-1</button>
        {ComicsJSON.comics.map((item, key) => {
          return (
            <Link
              key={`pagination${key}`}
              to={`/comics/${item}`}
              className={+active === +item ? "active" : ""}
            >
              {key + 1}
            </Link>
          );
        })}
        <button onClick={() => navigate(`/comics/${calculateNext(1)}`)} disabled={disable(-2, true)}>+1</button>
        <button onClick={() => navigate(`/comics/${calculateNext(5)}`)} disabled={disable(-6, true)}>+5</button>
      </div>
      <button onClick={goToRandom}>choose random</button>
    </section>
  );
}

export default Pagination;
