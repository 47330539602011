import "./About.scss";
import AboutSVG from "./about-svg/AboutSVG";
function About() {
  return (
    <section id="about">
      <a href="https://www.warpedpuppy.com/" target="_blank" rel="noreferrer">
        <AboutSVG />
      </a>
      <div id="about-text">
        <p>
          Literally millions* of people have asked me to chronicle my exciting
          Maine life, so here I am, doing just that.
        </p>
        <p>
          I've lead a very exciting life in which I definitely don't spend most
          of it sitting on the sofa worrying about things that have never happened
          and don't matter while watching abfab over and over and over and over and over and over again. And also Hot in Cleveland which is literally fucking hilarious.
        </p>
        <p className="mini-text">
          *literally no one. Tbh in total I probably know three people.
        </p>
      </div>
    </section>
  );
}

export default About;
