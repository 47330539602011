import "./ComicTemplate.scss";
import React from "react";
import DateService from "../services/date-service";
import ComicFrame from "./ComicFrame";
function ComicTemplate({ id }) {
  return (
    <section className="comic">
      <div>
		<ComicFrame num={1} id={id} />
		<ComicFrame num={2} id={id} />
		<ComicFrame num={3} id={id} />
		<ComicFrame num={4} id={id} />
      </div>
      <div>
        <div id="comic-date">{DateService.formatDate(id)}</div>
		<div id="comments">
			<label>comments:</label>
			<textarea disabled defaultValue="omfg jk nm as if"></textarea>
		</div>
      </div>
    </section>
  );
}

export default ComicTemplate;
