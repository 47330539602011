import Config from "../config";
import "./ComicFrame.scss";
import { useState } from "react";
const { API, CACHE_BLOCKER } = Config;
function ComicFrame({ id, num }) {
  const [loaded, setLoaded] = useState(false);
  return (
    <img
      src={`${API}/${id}/${num}.svg?cb=${CACHE_BLOCKER}`}
      alt={`comic frame ${num}`}
      onLoad={() => setLoaded(true)}
      className={loaded ? "loaded" : ""}
    />
  );
}

export default ComicFrame;
