import "./App.scss";
import NavBar from "./components/NavBar";
import About from "./pages/About";
import Contact from "./pages/Contact";
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import ComicPage from "./pages/ComicPage";
import NotFound from "./pages/NotFound";
function App() {
	const randoms = []
	function addToRandoms(item) {
		randoms.push(item)
	}
	function clearRandoms() {
		randoms.length = 0;
	}
  return (
	<BrowserRouter>
      <NavBar />
	  <main>
		<Routes>
			<Route path='/' element={<ComicPage clearRandoms={clearRandoms} randoms={randoms} addToRandoms={addToRandoms}/>} />
			<Route path='/contact' element={<Contact />} />
			<Route path='/comics/:id' element={<ComicPage clearRandoms={clearRandoms} randoms={randoms} addToRandoms={addToRandoms} />} />
			<Route path='/about' element={<About />} />
			<Route path='*' element={<NotFound />} />
		</Routes>
	  </main>

	</BrowserRouter>
  );
}

export default App;
