import './NavBar.scss';
import { Link } from 'react-router-dom';
function NavBar() {
	return ( 
		<header>
		<nav>
			<Link to={'/'}><img src={'/tugtug.png'} alt='tugtug'/></Link>
			<ul>
				<li><Link to={'/'}>comics</Link></li>
				<li><Link to={'/about'}>about</Link></li>
				<li><Link to={'/contact'}>contact</Link></li>
			</ul>
		</nav>
		{/* <h2>postcards from the gay edge in maine</h2> */}
		</header>
	 );
}

export default NavBar;