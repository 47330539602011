import Pagination from "../components/Pagination";
import ComicTemplate from "../templates/ComicTemplate";
import { useParams } from "react-router";
import ComicJSON from '../json/comics.json'
function ComicPage({randoms, addToRandoms, clearRandoms}) {
  let params = useParams();
  let id = params.id ? params.id : ComicJSON.comics[ComicJSON.comics.length - 1];
  return (
    <section id="comic-page">
      <Pagination randoms={randoms} addToRandoms={addToRandoms} active={id} clearRandoms={clearRandoms} />
      <ComicTemplate id={id} />
    </section>
  );
}

export default ComicPage;
